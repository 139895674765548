import { Box, FormHelperText, Theme, useMediaQuery } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { Link, To } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { ArrowLeftIcon20, Lock01Icon20 } from '../../../assets';
import { useDocumentTitle } from '../../../hooks';
import { Button } from '../../Button';
import { Input } from '../../Input';

interface ResetPasswordPageBodyProps {
  handleFormSubmit: (event: FormEvent<HTMLFormElement>, password: string) => Promise<void>;
  isSubmitting: boolean;
  redirectTo: To;
  setErrorMessageFunc: (message: string) => void;
  errorMessage: string;
  firstPasswordChange: boolean;
}

export const ResetPasswordPageBody = ({
  handleFormSubmit,
  isSubmitting,
  setErrorMessageFunc,
  redirectTo,
  errorMessage,
  firstPasswordChange,
}: ResetPasswordPageBodyProps) => {
  useDocumentTitle('Reset Password');
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isTwoPasswordsEqual, setIsTwoPasswordsEqual] = useState(true);
  const [mobileClickedSubmit, setMobileClickedSubmit] = useState(false);
  const equalMessage = 'Both passwords must match.';
  const minLengthMessage = 'Must be at least 8 characters';
  const alphaNumericMessage = 'Must contain at least one uppercase, one lowercase letter and a number';
  const firstLoginMessage = 'Welcome! Create your first password to get started.';
  const differentPasswordsMessage = 'Your new password must be different to previously used passwords.';
  const [backendRules, setBackendRules] = useState(false);
  const [backendErrors, setBackendErrors] = useState<string[]>([]);
  useEffect(() => {
    const validatePasswordAPI = async (password: string) => {
      try {
        const email = decodeURIComponent(new URLSearchParams(window.location.search).get('email') ?? '');
        const response = await client.post('/auth/validate-password-strength/', {
          password: password,
          userName: email,
        });
        return response.data;
      } catch (error) {
        const message = isAxiosError(error) ? error.response?.data.error || error.response?.data.errors : '';
        return { valid: false, errors: message };
      }
    };
    const timer = setTimeout(async () => {
      if (password && password.length >= 8 && checkIncludeAlphaNumeric(password)) {
        const result = await validatePasswordAPI(password);
        setBackendRules(result.valid);
        setBackendErrors(result.errors || '');
      }
    }, 750);

    return () => clearTimeout(timer);
  }, [password]);

  const checkIncludeAlphaNumeric = (password: string): boolean => {
    return !!(password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/\d/));
  };
  const checkPasswordRules = (password: string): boolean => {
    return !!(password.length >= 8 && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/\d/)) && backendRules;
  };

  const checkPasswordFieldsValidity = (): boolean => {
    return !!(
      password &&
      ((isMobileApp && !mobileClickedSubmit) || (confirmPassword && isTwoPasswordsEqual)) &&
      checkPasswordRules(password)
    );
  };

  useEffect(() => {
    if (!isMobileApp || mobileClickedSubmit) {
      if (password === confirmPassword) {
        setIsTwoPasswordsEqual(true);
      } else {
        setIsTwoPasswordsEqual(false);
      }
    }
  }, [password, confirmPassword, isMobileApp, mobileClickedSubmit]);
  return (
    <Box sx={{ mx: 'auto', width: 328, maxWidth: '100%', alignContent: 'center' }}>
      <Box
        sx={() => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mx: 'auto',
        })}
      >
        <Box
          component="form"
          onSubmit={(event) => {
            event.preventDefault(); // This stops the form from submitting traditionally.
            if (isMobileApp && !mobileClickedSubmit) {
              setMobileClickedSubmit(true);
            } else {
              handleFormSubmit(event, password);
            }
          }}
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            px: 2,
            py: 4,
            [theme.breakpoints.up('sm')]: {
              py: 6,
            },
          })}
        >
          <Box sx={{ mx: 'auto', width: '328px', maxWidth: '100%' }}>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                mb: 3,
                width: 48,
                height: 48,
                borderRadius: '50%',
                border: `1px solid ${theme.palette.stationGray[50]}`,
                background: theme.palette.common.white,
                boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
                mx: 'auto',
                color: theme.palette.stationBlue[600],
                [theme.breakpoints.up('sm')]: {
                  mb: 6,
                  width: 80,
                  height: 80,
                },
                '& svg': {
                  width: 24,
                  height: 24,
                  [theme.breakpoints.up('sm')]: {
                    width: 40,
                    height: 40,
                  },
                  '& path': {
                    stroke: theme.palette.stationBlue[600],
                  },
                },
              })}
            >
              <Lock01Icon20 />
            </Box>
            <Box
              component="h1"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                mt: 0,
                mb: 2,
                typography: 'heading3',
                textAlign: 'center',
                width: '282px',
                mx: 'auto',
                [theme.breakpoints.up('sm')]: {
                  fontSize: '32px',
                  mb: 3,
                },
              })}
            >
              Set new password
            </Box>
            <Box
              component="p"
              sx={(theme) => ({
                color: theme.palette.stationGray[600],
                mt: 0,
                mb: 5,
                typography: 'bodyMRegular',
                textAlign: 'center',
                mx: 'auto',
              })}
            >
              {firstPasswordChange ? firstLoginMessage : differentPasswordsMessage}
            </Box>
            {(!isMobileApp || !mobileClickedSubmit) && (
              <Box sx={{ mb: isMobileApp ? 5 : 4 }}>
                <Input
                  disabled={isSubmitting}
                  error={!!errorMessage}
                  label="Password"
                  type={'password'}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.currentTarget.value);
                    setErrorMessageFunc('');
                  }}
                />
                <FormHelperText>
                  {password.length < 8
                    ? minLengthMessage
                    : !checkIncludeAlphaNumeric(password)
                      ? alphaNumericMessage
                      : backendErrors[0]}
                </FormHelperText>
              </Box>
            )}
            {(!isMobileApp || mobileClickedSubmit) && (
              <Box sx={{ mb: 5 }}>
                <Input
                  color={isTwoPasswordsEqual ? 'primary' : 'error'}
                  disabled={isSubmitting}
                  error={!!errorMessage}
                  label="Confirm password"
                  type={'password'}
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.currentTarget.value);
                    setErrorMessageFunc('');
                  }}
                />
                {!isTwoPasswordsEqual && password.length >= 8 && confirmPassword.length > 0 && (
                  <FormHelperText>{equalMessage}</FormHelperText>
                )}
              </Box>
            )}
            <Box>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={!checkPasswordFieldsValidity()}
                loading={isSubmitting}
                sx={(theme) => ({
                  mb: 2,
                  width: '100%',
                  [theme.breakpoints.up('sm')]: { mb: 5.5 },
                })}
              >
                Continue
              </Button>
            </Box>
            <Box>
              <Button size="large" component={Link} to={redirectTo} startIcon={<ArrowLeftIcon20 />} sx={{ width: '100%' }}>
                Back to log in
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
