import { Box, Checkbox } from '@mui/material';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { CardCertBadges, CustomTable, LetterAvatar, TeamBadge, TableProps } from '@stationwise/component-module';
import { Employee, Rank } from '@stationwise/share-types';

interface TeamSettingsTableProps {
  employees: Employee[];
  selectedEmployeeIds: string[];
  setSelectedEmployeeIds: React.Dispatch<React.SetStateAction<string[]>>;
  hideCheckbox?: boolean;
  setSelectedRanks: React.Dispatch<React.SetStateAction<Rank[]>>;
}

export const TeamSettingsTable = memo(
  ({ employees, selectedEmployeeIds, setSelectedEmployeeIds, hideCheckbox, setSelectedRanks }: TeamSettingsTableProps) => {
    const getRowProps = () => {
      return {
        sx: { cursor: 'pointer', '& td': { position: 'relative' } },
      };
    };

    const handleClick = (id: string) => {
      if (id === '-1') {
        if (selectedEmployeeIds.length === employees.length) {
          setSelectedEmployeeIds([]);
          setSelectedRanks([]);
        } else {
          setSelectedEmployeeIds(employees.map((emp) => emp.id));
          setSelectedRanks(employees.map((emp) => emp.rank) as Rank[]);
        }
      } else {
        setSelectedEmployeeIds((prev) => {
          const isSelected = prev.includes(id);
          const updatedIds = isSelected ? prev.filter((item) => item !== id) : [...prev, id];

          if (isSelected) {
            setSelectedRanks((prevRanks) => {
              const employee = employees.find((emp) => emp.id === id);
              return prevRanks.filter((rank) => rank.id !== employee?.rank?.id);
            });
          } else {
            setSelectedRanks((prevRanks) => {
              const employee = employees.find((emp) => emp.id === id);
              const rank = employee?.rank;
              if (!rank) return prevRanks;
              const isRankInArray = prevRanks.some((item) => item.id === rank.id);
              if (!isRankInArray) {
                return [...prevRanks, rank];
              }
              return prevRanks;
            });
          }

          return updatedIds;
        });
      }
    };

    const columns: TableProps['columns'] = [
      {
        id: 'checkbox',
        label: hideCheckbox ? (
          ''
        ) : (
          <Checkbox
            data-cy="employee-checkbox"
            checked={selectedEmployeeIds.length === employees.length}
            color="secondary"
            onClick={() => handleClick('-1')}
          />
        ),
        minWidth: 20,
        align: 'center',
      },
      { id: 'name', label: 'NAME', minWidth: 100, align: 'left' },
      { id: 'rank', label: 'RANK', minWidth: 100, align: 'center' },
      { id: 'station', label: 'STATION', minWidth: 100, align: 'center' },
      { id: 'apparatus', label: 'APPARATUS', minWidth: 100, align: 'center' },
      { id: 'shift', label: 'SHIFT', minWidth: 100, align: 'center' },
      { id: 'employee_id', label: 'EMPL. ID', minWidth: 50, align: 'center' },
      {
        id: 'certifications',
        label: 'CERTIFICATIONS',
        minWidth: 100,
        align: 'left',
      },
      { id: 'is_active', label: 'ACCOUNT STATUS', minWidth: 100, align: 'center' },
    ];

    const data: TableProps['data'] = employees.map((employee, index) => ({
      employee,

      checkbox: hideCheckbox ? (
        ''
      ) : (
        <Checkbox
          {...(index === 0 ? { 'data-cy': 'employee-checkbox-0' } : {})}
          checked={selectedEmployeeIds.includes(employee.id)}
          color="secondary"
          onClick={() => handleClick(employee.id)}
        />
      ),

      name: (
        <Box
          component={Link}
          to={`/app/employees/${employee.id}`}
          sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), alignItems: 'center', textDecoration: 'none' })}
        >
          <LetterAvatar firstName={employee.name} color="lightblue" />
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
            <Box>{employee.name}</Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[600] })}>{employee.email}</Box>
          </Box>
        </Box>
      ),

      rank: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employee.rank?.name || 'N/A'}
        </Box>
      ),
      station: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
            ? employee.plannedShiftDetails[0].station
            : 'Unassigned'}
        </Box>
      ),

      apparatus: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
            ? employee.plannedShiftDetails[0].apparatus
            : 'Unassigned'}
        </Box>
      ),
      shift:
        employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0 ? (
          <TeamBadge
            team={{
              name: employee.plannedShiftDetails[0].teamName,
              color: employee.plannedShiftDetails[0].shiftColor,
            }}
          />
        ) : (
          <TeamBadge team={{ name: 'Unassigned', color: 'gray' }} />
        ),
      employee_id: (
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[600],
            typography: 'bodySRegular',
          })}
        >
          {employee.payrollId}
        </Box>
      ),
      certifications: employee.certifications ? (
        <CardCertBadges certs={employee.certifications} />
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>N/A</Box>
      ),
      is_active: (
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: employee.isActive ? theme.palette.stationGreen[500] : theme.palette.stationGray[500],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
            })}
          >
            {employee.isActive ? 'Active' : 'Inactive'}
          </Box>
        </Box>
      ),
    }));

    data.forEach((row) => {
      columns.forEach((column) => {
        row[column.id] = (
          <>
            <Box
              component={Link}
              to={`/app/employees/${row['employee'].id}`}
              tabIndex={-1}
              sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
            {row[column.id]}
          </>
        );
      });
    });

    return <CustomTable columns={columns} data={data} getRowProps={getRowProps} />;
  },
);

TeamSettingsTable.displayName = 'Memo(TeamSettingsTable)';
